
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "b-tab-pane" })
export default class BTabPane extends Vue {
  @Prop(Boolean) readonly active!: boolean;

  get css(): Record<string, any> {
    return {
      show: this.active,
      active: this.active,
    };
  }
}
